import { ModalController } from '@ionic/angular';
import { API } from 'src/app/api/api';
import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
// import { SafariViewController } from '@ionic-native/safari-view-controller/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  templateUrl: './modal-cadastro.component.html',
  styleUrls: ['./modal-cadastro.component.scss'],
})
export class ModalCadastro implements OnInit {
  constructor(
    private api: API,
    private modalCtrl: ModalController,
    private modalController: ModalController,
    private platform: Platform,
    // private safariViewController: SafariViewController,
    private iab: InAppBrowser
  ) {}

  ngOnInit(): void {}

  AllowSpeech() {
    this.api.readText('');
    this.modalController.dismiss();
  }

  DenySpeech() {
    this.modalController.dismiss();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  storeLink() {
    const apple = 'https://apps.apple.com/br/app/156-osasco/id1513632904';
    const android =
      'https://play.google.com/store/apps/details?id=br.com.osasco.atendimento&hl=pt_BR&gl=US';

    if (this.platform.is('ios')) {
      this.iab.create(apple);

      // alert("https://apps.apple.com/br/app/156-osasco/id1513632904");
    } else {
      this.iab.create(android);
      // alert("https://play.google.com/store/apps/details?id=br.com.osasco.atendimento&hl=pt_BR&gl=US");
    }
  }
}
