import { Injectable } from '@angular/core';

@Injectable()
export class ConstantsService {
    sessionId = '';
    appId = '58979381-ebed-4a68-b88f-cf40f3ea9210';
    authSuccess = false;
    appToken = '';
    sessionTime = new Date();
    sessionAssist = '';
    showKeyboard = false;
    showLoading = false;
    showVazio = false;
    showMicButton = true;
    apiUrl =  'https://api-assistentevirtual.osasco.sp.gov.br' ;
    latitude = '';
    longitude = '';
    mutemode = false;
    footer = false;
    scroll = false;
    debug = '';
    termos = '';
}