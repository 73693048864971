import { Injectable } from "@angular/core";
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { File } from "@ionic-native/file/ngx";

@Injectable()
export class FileService {
  constructor(private file: File, private fileOpener: FileOpener) {}

  saveFileAsJSON(object: any, fileName: string) {
    localStorage.setItem(fileName, JSON.stringify(object));
  }

  getFile(fileName: string) {
    const retrievedObject = localStorage.getItem(fileName);

    if (retrievedObject === null) {
      return null;
    }

    return retrievedObject;
  }

  deleteFile(fileName: string) {
    localStorage.removeItem(fileName);
  }

  downloadPDFBase64(base64): Blob {
    const byteArray = new Uint8Array(
      atob(base64)
        .split("")
        .map((char) => char.charCodeAt(0))
    );
    const blob = new Blob([byteArray], { type: "application/pdf" });

    return blob;
  }

  downloadWAVBase64(base64): Blob {
    const byteArray = new Uint8Array(
      atob(base64)
        .split("")
        .map((char) => char.charCodeAt(0))
    );
    const blob = new Blob([byteArray], { type: "audio/wav" });

    return blob;
  }

  async blobToBase64(blob): Promise<string> {
    return new Promise(function (resolve) {
      var reader = new FileReader();
      var b64data;
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        var data = reader.result;
        b64data = data as string;
        b64data = b64data.split(",")[1];
        console.log(b64data);
        resolve(b64data);
      };
    });
  }

  saveFile(blob: Blob, fileName: string, extension: string) {
    var FileSaver = require("file-saver");
    FileSaver.saveAs(blob, fileName + extension);
  }
}
