import { Status } from './../../api/resposta';
import { FileService } from '../../services/file-service';
import {
  ChatMessage,
  ChatMessageService,
  MessageType,
} from '../../services/user-message-service';
import { API } from '../../api/api';

import {
  Collapsable,
  Lista,
  Item
} from '../../api/resposta';

import {
  Component,
  Input,
  OnInit
} from '@angular/core';



@Component({
  selector: 'app-collapsable',
  templateUrl: './collapsable.component.html',
  styleUrls: ['./collapsable.component.scss'],
})
export class CollapsableComponent implements OnInit {
  @Input() collapsable: Collapsable;
  
  @Input() list: Lista;
  items: Item[];

  public titulo: string;
  public itens: Lista[];
  public switches: boolean[];
  private parcelas: Array<string[]>;
  private data: string[];

  valoresParcelas: Array<string>;
  switchAVista: Array<boolean>;
  numparc: any;

  constructor(
    private api: API,
    private msgService: ChatMessageService,
    private fs: FileService,
  ) { }

  
  ngOnInit() {
    this.titulo = this.collapsable.titulo;
    this.itens = this.collapsable?.itens;
    this.switches = new Array(this.itens.length).fill(false);
  }


  Toggle(idx: number) {
    this.switches[idx] = !this.switches[idx];
  }
  
  
  Hide(idx: number) {
    this.switches[idx] = false;
  }

  
  Show(idx: number) {
    this.switches[idx] = true;
  }


}
