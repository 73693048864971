import { async } from '@angular/core/testing';
import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ModalConfiguracoesComponent } from '../modal-configuracoes/modal-configuracoes.component';
import { ModalServicesComponent } from '../modal-itens-services/modal-itens-services.component';
import { ModalController, Platform } from '@ionic/angular';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() showBackButton: boolean;

  constructor(
    private location: Location,
    private router: Router,
    private modalController: ModalController,
  ) { }

  ngOnInit() { }

  goBack() {
    this.location.back();
  }

  async modalConfig() {
    let modal = await this.modalController.create({
      component: ModalConfiguracoesComponent,
      cssClass: 'modal-config'
    });
    return await modal.present();
}

// async servicesModal() {
//   let modal = await this.modalController.create({
//     component: ModalItensServicosPage,
//     id: 'modal-servicos'
//   });
//   return await modal.present();
// }

async modalServices() {
  let modal = await this.modalController.create({
    component: ModalServicesComponent,
    id: 'modal-services',
    cssClass: 'modal-services'
  });
  return await modal.present();
}


  // async modalConfig() {
  //     let modal = await this.modalController.create({
  //       component: ModalConfiguracoesComponent
  //     });
  //     return await modal.present();
  // }


}