import { API } from './../../api/api';
import { Expression } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-botoes-sugestoes',
  templateUrl: './botoes-sugestoes.component.html',
  styleUrls: ['./botoes-sugestoes.component.scss'],
})

export class BotoesSugestoesComponent implements OnInit {
  @Input() buttons: string[];
  @Input() click: Expression;
  constructor(private api: API) { }

  ngOnInit() { }

  sendText(text: string){
    this.api.texto(text);
  }
  
}
