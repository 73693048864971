import { environment } from "./../environments/environment";
import { Component } from "@angular/core";
import { ConstantsService } from "./services/constants-service";
import {
  Platform,
  ModalController,
  NavParams,
  NavController,
} from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { API } from "./api/api";
import { AppInitService } from "./services/app-init-service";
import { FileService } from "./services/file-service";
import { NavigationExtras, Router } from "@angular/router";
import { SendIntent } from "send-intent";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})

export class AppComponent {
  private sessionStarted = false;
  private urlParameters: Array<any> = [];
  private fileName = "primeroacesso";
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private globals: ConstantsService,
    private api: API,
    private ais: AppInitService,
    private fileService: FileService,
    private router: Router,
    private modalController: ModalController
  ) {

    this.initializeApp();
  }

  initializeApp() {
    this.router.navigateByUrl(
      this.checkSegundoAcesso() ? "autenticacao" : "intro-slide",
      { replaceUrl: true }
    );

    this.handleIntent();

    if (this.platform.is('ios')) {

      window.addEventListener("sendIntentReceived", () => {
        this.handleIntent();
      })
    }

    /*
      Configuração de tempo do SplashScreen
      Deixar o mesmo tempo do config.xml
    */

    this.platform.ready().then(() => {
      setTimeout(() => {
        this.splashScreen.hide();
      }, 3000);
    })

  }

  redirect() {
    this.api.getAceiteDeTermos().then((aceite) => {
      if (aceite) {
        this.router.navigateByUrl("home", { replaceUrl: true });
      } else {
        this.router.navigateByUrl("termo-de-uso", { replaceUrl: true });
      }
    })
  }

  private handleIntent() {
    SendIntent.checkSendIntentReceived().then((result: any) => {
      if (result) {
        this.api
          .auth(result.nmEmail, null, result.tokenOsasco)
          .then((result: any) => {
            if (this.globals.authSuccess) {
              this.checkSegundoAcesso() ? this.redirect() : this.router.navigateByUrl("intro-slide", { replaceUrl: true })
            }
            return;
          });
      } else {
        this.router.navigateByUrl(
          this.checkSegundoAcesso() ? "autenticacao" : "intro-slide"
        );
      }
    });
  }

  checkSegundoAcesso() {
    const segundoacesso = this.fileService.getFile(this.fileName);
    if (!segundoacesso) {
      console.log("false");
      return false;
    }
    console.log("true");
    return true;
  }

  getUrlParams() {
    if (document.URL.indexOf("?") > 0) {
      let splitURL = document.URL.split("?");
      let splitParams = splitURL[1].split("&");
      let i: any;
      for (i in splitParams) {
        let singleURLParam = splitParams[i].split("=");
        let urlParameter = {
          name: singleURLParam[0],
          value: singleURLParam[1],
        };
        this.urlParameters.push(urlParameter);
      }
    }
  }
}
