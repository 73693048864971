import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';
import { ModalClassificacaoExperienciaComponent } from './../components/modal-classificacao-experiencia/modal-classificacao-experiencia.component';
import { ModalController, ToastController } from '@ionic/angular';
import {
  MessageType,
  ChatMessage,
  ChatMessageService,
} from './../services/user-message-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstantsService } from '../services/constants-service';
import { Resposta, Chat, Collapsable } from './resposta';
import { Injectable } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Platform } from '@ionic/angular';
import { EmailValidator } from '@angular/forms';

@Injectable()
export class API {
  private urlParameters: Array<any> = [];
  private httpOptions = {
    headers: new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.globals.appToken}`
    ),
  };
  private redirectUrl = '';

  constructor(
    private globals: ConstantsService,
    private http: HttpClient,
    private msgService: ChatMessageService,
    private geolocation: Geolocation,
    private modalController: ModalController,
    private tts: TextToSpeech,
    private platform: Platform,
    public toastController: ToastController
  ) {}

  async auth(email?: string, senha?: string, token?: string) {
    const obj = {
      token156: token,
      email: email,
      senha: senha,
    };

    await this.http
      .post(this.globals.apiUrl + '/v1/autenticacao', obj)
      .toPromise()
      .then((d) => {
        this.globals.authSuccess = d['sucesso'];
        this.globals.appToken = d['token'];
        this.globals.sessionTime = new Date(d['validoAte']);
      })
      .catch((err) => {
        this.globals.authSuccess = false;
        console.log(err);
      });
    this.httpOptions = {
      headers: new HttpHeaders().set(
        'Authorization',
        `Bearer ${this.globals.appToken}`
      ),
    };
  }

  getUrlParams() {
    if (document.URL.indexOf('?') > 0) {
      let splitURL = document.URL.split('?');
      let splitParams = splitURL[1].split('&');
      let i: any;
      for (i in splitParams) {
        let singleURLParam = splitParams[i].split('=');
        let urlParameter = {
          name: singleURLParam[0],
          value: singleURLParam[1],
        };
        this.urlParameters.push(urlParameter);
      }
    }
  }

  async acao(acao: string) {
    this.globals.showLoading = true;
    let resposta: Resposta;
    const obj = {
      sessao: this.globals.sessionAssist,
      acao: acao,
    };

    await this.http
      .post(this.globals.apiUrl + '/v1/chat/acao', obj, this.httpOptions)
      .subscribe((d) => {
        resposta = d as Resposta;

        this.globals.sessionAssist = d['chat']['sessao'];

        this.handleResponse(resposta);

        this.globals.showLoading = false;

        console.log(resposta);
        return resposta;
      });
  }

  async inicio() {
    await this.http
      .post(this.globals.apiUrl + '/v1/chat/inicio', '', this.httpOptions)
      .toPromise()
      .then((d) => {
        let resposta: Resposta;
        resposta = d as Resposta;
        this.globals.sessionAssist = d['chat']['sessao'];
        this.handleResponse(resposta);
      });
  }

  async login(email: string, senha: string) {
    await this.auth(email, senha);
  }

  async texto(txt: string) {
    const chatMsg: ChatMessage = {
      message: txt,
      msgType: MessageType.UserMessage,
    };

    this.msgService.publish(chatMsg);

    this.globals.showLoading = true;

    await this.getLocalization();

    const obj = {
      sessao: this.globals.sessionAssist,
      texto: txt,
      parametros: {
        coordenadas: {
          latitude: this.globals.latitude,
          longitude: this.globals.longitude,
        },
      },
    };

    let resposta: Resposta;
    await this.http
      .post(this.globals.apiUrl + '/v1/chat/texto', obj, this.httpOptions)
      .subscribe(async (d) => {
        resposta = d as Resposta;
        this.globals.sessionAssist = d['chat']['sessao'];

        this.handleResponse(resposta);
        this.globals.showLoading = false;

        console.log(resposta);
        return resposta;
      });
  }

  async audio(b64: string) {
    this.globals.showLoading = true;

    await this.getLocalization();

    const obj = {
      sessao: this.globals.sessionAssist,
      audioBase64: b64,
      parametros: {
        coordenadas: {
          latitude: this.globals.latitude,
          longitude: this.globals.longitude,
        },
      },
    };

    let resposta: Resposta;
    await this.http
      .post(this.globals.apiUrl + '/v1/chat/texto', obj, this.httpOptions)
      .subscribe(async (d) => {
        const chatMsg: ChatMessage = {
          message: d['chat']['texto'],
          msgType: MessageType.UserMessage,
        };
        this.msgService.publish(chatMsg);

        resposta = d as Resposta;
        this.globals.sessionAssist = d['chat']['sessao'];

        this.handleResponse(resposta);
        this.globals.showLoading = false;

        console.log(resposta);
        return resposta;
      });
  }

  async rating(
    rating: number,
    sgst: string[],
    comment: string
  ): Promise<boolean> {
    const obj = {
      avaliacao: rating,
      melhorias: sgst,
      comentario: comment,
    };

    console.log(obj);

    return await this.http
      .post(
        this.globals.apiUrl + '/v1/chat/pesquisa-satisfacao',
        obj,
        this.httpOptions
      )
      .toPromise()
      .then((d: any) => {
        if (d?.mensagem) {
          alert(d?.mensagem);
          throw d?.sucesso;
        }
        return d?.sucesso;
      })
      .catch((err) => {
        return false;
      });
  }

  private async handleResponse(resposta: Resposta) {
    resposta.chat.respostas.forEach((r) => {
      if (r.texto?.escrita) {
        const chatMsg: ChatMessage = {
          message: r.texto.escrita,
          card: resposta.card,
          msgType: MessageType.AssistantMessage,
        };
        this.msgService.publish(chatMsg);
      }

      if (r.lista?.itens) {
        const newsList: ChatMessage = {
          msgType: MessageType.List,
          list: r?.lista,
        };

        this.msgService.publish(newsList);
      }

      if (r?.card) {
        const cardAnwser: ChatMessage = {
          msgType: r?.card?.status ? MessageType.Status : MessageType.Card,
          card: r?.card,
        };

        this.msgService.publish(cardAnwser);
      }

      if (r?.imagem) {
        const imgAnwser: ChatMessage = {
          msgType: MessageType.Image,
          image: r?.imagem,
        };

        this.msgService.publish(imgAnwser);
      }

      if (r?.tabelaHorario) {
        const tableAnwser: ChatMessage = {
          msgType: MessageType.TimeTable,
          table: r?.tabelaHorario,
        };

        this.msgService.publish(tableAnwser);
      }

      if (r?.collapsable) {
        const colAnwser: ChatMessage = {
          msgType: MessageType.Collapsable,
          collapsable: r?.collapsable,
        };

        this.msgService.publish(colAnwser);
      }

      if (r?.collapsableParcelamento) {
        const colAnwser: ChatMessage = {
          msgType: MessageType.CollapsableParcelamento,
          collapsableParcelamento: r?.collapsableParcelamento,
        };

        this.msgService.publish(colAnwser);
      }

      if (r?.collapsableApp) {
        const colAnwser: ChatMessage = {
          msgType: MessageType.CollapsableApp,
          collapsableApp: r?.collapsableApp,
        };

        this.msgService.publish(colAnwser);
      }
      if (r?.collapsableDividas) {
        const colAnwser: ChatMessage = {
          msgType: MessageType.CollapsableDividas,
          collapsableDividas: r?.collapsableDividas,
        };

        this.msgService.publish(colAnwser);
      }
    });

    this.readAllText(resposta.chat.respostas);

    if (resposta.chat.sugestoes) {
      const chatMsg: ChatMessage = {
        suggestion: resposta.chat.sugestoes,
        msgType: MessageType.Suggestion,
      };
      this.msgService.publish(chatMsg);
    }
    // acesso rapido
    if (resposta.chat.acessoRapido) {
      const chatMsg: ChatMessage = {
        msgType: MessageType.acessoRapido,
        acessoRapido: resposta?.chat?.acessoRapido,
      };
      this.msgService.publish(chatMsg);
    }

    if (resposta?.chat.mostrarPesquisaSatisfacao === true) {
      let modal = await this.modalController.create({
        component: ModalClassificacaoExperienciaComponent,
        cssClass: 'modal-exp',
      });
      return await modal.present();
    }
  }

  async getLocalization() {
    await this.geolocation
      .getCurrentPosition()
      .then((resp) => {
        this.globals.latitude = resp.coords.latitude.toString();
        this.globals.longitude = resp.coords.longitude.toString();
      })
      .catch((error) => {
        console.log('Error getting location', error);
      });
  }

  async termos(aceitar: boolean) {
    const agree = aceitar ? 'aceitar' : 'recusar';

    return await this.http
      .post(
        this.globals.apiUrl + '/v1/usuario/termos/' + agree,
        '',
        this.httpOptions
      )
      .toPromise()
      .then((d: Resposta) => {
        console.log(d);
      });
  }

  async getAceiteDeTermos(): Promise<boolean> {
    return await this.http
      .get(this.globals.apiUrl + '/v1/usuario/termos/', this.httpOptions)
      .toPromise()
      .then((d: Resposta) => {
        return d['resposta']['aceitouTermos'];
      });
  }

  async DAM(selectParcelas: string[]): Promise<Chat> {
    this.globals.showLoading = true;

    const obj = {
      sessao: this.globals.sessionAssist,
      parcelas: selectParcelas,
    };

    let resposta: Resposta;
    return await this.http
      .post(this.globals.apiUrl + '/v1/chat/dam', obj, this.httpOptions)
      .toPromise()
      .then((d: any) => {
        resposta = d as Resposta;

        console.log(resposta);

        if (d?.mensagem) {
          const chatMsg: ChatMessage = {
            message: d?.mensagem,
            msgType: MessageType.AssistantMessage,
          };
          this.msgService.publish(chatMsg);
          return null;
        }
        this.globals.showLoading = false;
        return resposta.chat;
      });
  }

  //Sintese de voz
  async readText(txt: string) {
    console.log(navigator.languages);
    this.tts.speak({
      text: txt,
      locale: 'pt-BR',
    });
  }

  async readAllText(respostas: Resposta[]) {
    for (const resposta of respostas) {
      if (resposta?.texto?.fala && this.globals.mutemode === false) {
        if (this.platform.is('ios')) {
          await this.tts.speak({
            text: resposta?.texto?.fala,
            locale: 'pt-BR',
            rate: 1.5,
          });
        } else {
          await this.tts.speak({
            text: resposta?.texto?.fala,
            locale: 'pt-BR',
            rate: 1,
          });
        }
      }
    }
  }

  async resetSenha(cpf, email) {
    const obj = {
      cpf: cpf,
      email: email,
    };

    return await this.http
      .post(
        this.globals.apiUrl + '/v1/autenticacao/reset-senha/',
        obj,
        (this.httpOptions = {
          headers: new HttpHeaders().set(
            'Authorization',
            `Bearer ${this.globals.appToken}`
          ),
        })
      )
      .toPromise();
  }

  async cadastro(obj: any): Promise<boolean> {
    return await this.http
      .post(
        'http://api-156osasco.osasco.sp.gov.br/crm156-ws/myCity/cadastrarPessoa' ,
        obj
      )
      .toPromise()
      .then(async (d) => {
        const toast = await this.toastController.create({
          color: 'dark',
          duration: 2000,
          message: 'Cadastrado com sucesso',
          keyboardClose: true,
        });

        toast.present();

        return true;
      })
      .catch(async (err) => {
        console.log(err);
        const toast = await this.toastController.create({
          color: 'dark',
          duration: 2000,
          message: err['error']['message'],
          keyboardClose: true,
        });

        toast.present();

        return false;
      });
  }

  async buscarCEP(cepNum: string): Promise<any> {
    let bearer = '';

    const obj = {
      codigoLicenca: 'be0588a4-600f-4321-bac2-5917aa07aa0c',
    };

    return await this.http
      .post(
        'https://api-enderecos.ici.curitiba.org.br/v1/autenticacao/autentica',
        obj
      )
      .toPromise()
      .then(async (d) => {
        bearer = d['secret'];
        const httpOptions = {
          headers: new HttpHeaders().set('Authorization', `Bearer ${bearer}`),
        };
        const json = {
          cep: cepNum,
        };
        return await this.http
          .post(
            'https://api-enderecos.ici.curitiba.org.br/v1/logradouro/consulta',
            json,
            httpOptions
          )
          .toPromise()
          .then((d) => {
            const add = {
              logradouro:
                d['logradouros'][0]['tipoLogradouro'] +
                ' ' +
                d['logradouros'][0]['logradouro'],
              bairro:
                d['logradouros'][0]['bairro']['bairroInicial']['nomeCompleto'],
              cidade: d['logradouros'][0]['nomeCidade'],
              uf: d['logradouros'][0]['siglaEstado'],
            };
            return add;
          });
      })
      .catch(async (err) => {
        console.log(err);
        const toast = await this.toastController.create({
          color: 'dark',
          duration: 2000,
          message: err,
          keyboardClose: true,
        });

        await toast.present();
      });
  }
}
