import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-dialogo-usuario',
  templateUrl: './dialogo-usuario.component.html',
  styleUrls: ['./dialogo-usuario.component.scss'],
})
export class DialogoUsuarioComponent implements OnInit {
  @Input() msg: string;
  @Output() scrollDiag = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.scrollDiag.emit();
    console.log("evento usuario");
  }

}
