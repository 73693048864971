
// declarar e exportar os novos componentes
import { CardConfirmacaoComponent } from "./card-confirmacao/card-confirmacao.component";
import { StarRatingBarComponent } from "./star-rating-bar/star-rating-bar.component";
import { CardRespostaComponent } from "./card-resposta/card-resposta.component";
import { HeaderComponent } from "./header/header.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { Component } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { ComponentsPageRoutingModule } from "./components-routing.module";
import { ComponentsPage } from "./components.page";
import { FooterComponent } from "./footer/footer.component";
import { LoadingComponent } from "./loading/loading.component";
import { CardsItensComponent } from "./cards-itens/cards-itens.component";
import { CardProtocoloComponent } from "./card-protocolo/card-protocolo.component";
import { DialogoAssistenteComponent } from "./dialogo-assistente/dialogo-assistente.component";
import { DialogoUsuarioComponent } from "./dialogo-usuario/dialogo-usuario.component";
import { ModalConfiguracoesComponent } from "./modal-configuracoes/modal-configuracoes.component";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { ModalClassificacaoExperienciaComponent } from "./modal-classificacao-experiencia/modal-classificacao-experiencia.component";
import { ListaComponent } from "./lista/lista.component";
import { BotoesSugestoesComponent } from "./botoes-sugestoes/botoes-sugestoes.component";
import { ModalServicesComponent } from "./modal-itens-services/modal-itens-services.component";
import { CollapsableComponent } from "./collapsable/collapsable.component";
import { ListaSolicitacaoComponent } from './lista-solicitacao/lista-solicitacao.component';
import { ModalCadastro } from "./modal-cadastro/modal-cadastro.component"
import { VazioComponent } from './vazio/vazio.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentsPageRoutingModule,
  ],

  exports: [
    FooterComponent,
    HeaderComponent,
    BotoesSugestoesComponent,
    StarRatingBarComponent,
    DialogoAssistenteComponent,
    CardsItensComponent,
    DialogoUsuarioComponent,
    CardConfirmacaoComponent,
    ListaComponent,
    CardRespostaComponent,
    CardProtocoloComponent,
    LoadingComponent,
    ModalConfiguracoesComponent,
    ModalClassificacaoExperienciaComponent,
    ModalServicesComponent,
    VazioComponent,
    CollapsableComponent,
    VazioComponent,
    ModalCadastro,
  ],

  declarations: [
    ComponentsPage,
    FooterComponent,
    HeaderComponent,
    BotoesSugestoesComponent,
    StarRatingBarComponent,
    DialogoAssistenteComponent,
    CardsItensComponent,
    DialogoUsuarioComponent,
    CardConfirmacaoComponent,
    ListaComponent,
    CardRespostaComponent,
    CardProtocoloComponent,
    VazioComponent,
    LoadingComponent, 
    ModalConfiguracoesComponent,
    ModalClassificacaoExperienciaComponent,
    ModalServicesComponent,
    CollapsableComponent,
    ListaSolicitacaoComponent,
    VazioComponent,
    ModalCadastro
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsPageModule { }