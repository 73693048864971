import { ConstantsService } from "./../../services/constants-service";
import { API } from "./../../api/api";
import { Component, OnInit } from "@angular/core";
import { FileService } from "./../../services/file-service";
import { Platform } from "@ionic/angular";
import {
  SpeechRecognition,
  SpeechRecognitionListeningOptions,
} from "@ionic-native/speech-recognition/ngx";
import { TextToSpeech } from "@ionic-native/text-to-speech/ngx";
import { IonContent } from "@ionic/angular";


@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  public usrInput = "";
  public status = false;
  public footershow = true;
  voiceActiveSectionListening = false;
  voiceText: any;
  record: any;
  speechEvents: any;
  mutemode = false;
  click = false;
  content: IonContent;

  constructor(
    private api: API,
    private fs: FileService,
    public globals: ConstantsService,
    private platform: Platform,
    private speechRecognition: SpeechRecognition,
    private tts: TextToSpeech,


  ) { }

  ngOnInit() {

    document.getElementById("footer__desk").style.display = "none";
    document.getElementById("footer__mobile").style.display = "block";

  }

  //Função utilizada para envio de msgs
  async sendText(text: string) {
    if (text.replace(/\s/g, "").length) {

      this.api.texto(text);
      this.usrInput = "";
    }
  }
  //Função utilizada para envio de msgs

  //Função de transcrição de voz
  async startVoiceRecognition() {
    this.voiceActiveSectionListening = !this.voiceActiveSectionListening
    this.speechRecognition.requestPermission().then(
      () => console.log("Granted"),
      () => console.log("Denied")
    );
    this.speechRecognition
      .isRecognitionAvailable()
      .then((available: boolean) => console.log(available));
    let options = { "language": "pt-BR" }
    this.speechRecognition.startListening(options).subscribe(
      async (matches: string[]) => await this.sendText(matches[0]),
      (onerror) => console.log("error:", onerror)
    );

    setTimeout(() => {

      this.voiceActiveSectionListening = false;
      
      this.speechRecognition.stopListening();

    }, 6000);

    setInterval(() => {

      // this.globals.showVazio = true;

    }, 200)

  }

  //Função de transcrição de voz
  //conversão para base 64
  async process(blob: Blob) {
    const b64 = await this.fs.blobToBase64(blob);
    await this.api.audio(b64);
    this.content?.scrollToBottom(500)
  }
  //conversão para base 64

  //Função de mutar a Assistente
  mutemodechanger() {
    this.globals.mutemode = !this.globals.mutemode;
    this.tts.speak("")
    if (this.globals.mutemode == false) {
      console.log("Mute: OFF");
    } else {
      console.log("Mute: ON");
    }
  }
  //Função de mutar a Assistente


  toggleTeclado() {
    document.getElementById("footer__desk").style.display = "block";
    document.getElementById("footer__mobile").style.display = "none";
  }

  togglemic() {
    document.getElementById("footer__desk").style.display = "none";
    document.getElementById("footer__mobile").style.display = "block";
    this.startVoiceRecognition();
  }
}