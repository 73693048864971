
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppInitService {

    private fooSubject = new Subject<boolean>();

    publish(data: boolean) {
        this.fooSubject.next(data);
    }

    getObservable(): Subject<boolean> {
        return this.fooSubject;
    }
}
