import { ConstantsService } from './../../services/constants-service';
import { API } from "./../../api/api";
import { Component, OnInit, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Item, Lista } from "src/app/api/resposta";
import {
  ChatMessage,
  ChatMessageService,
  MessageType,
} from "src/app/services/user-message-service";
import { ModalClassificacaoExperienciaComponent } from "../modal-classificacao-experiencia/modal-classificacao-experiencia.component";
import { ModalServicesComponent } from "../modal-itens-services/modal-itens-services.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-modal-configuracoes",
  templateUrl: "./modal-configuracoes.component.html",
  styleUrls: ["./modal-configuracoes.component.scss"],
})
export class ModalConfiguracoesComponent implements OnInit {
  constructor(
    private modalController: ModalController,
    private modalCtrl: ModalController,
    private msgService: ChatMessageService,
    private globals: ConstantsService,
    private router: Router
  ) {}

  ngOnInit() {}

  async modalExperience() {
    const modal = await this.modalController.create({
      component: ModalClassificacaoExperienciaComponent,
      cssClass: "modal-exp",
    });
    return await modal.present();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  async faqtributos() {
    const icone = "faq.svg";
    this.closeModal();

    const listItems: Item[] = new Array();

    const iptu: Item = {
      acao: "",
      titulo: "O que é IPTU?",
      descricao:
        "Qual a definição de IPTU (Imposto sobre a Propriedade Predial e Territorial Urbana)",
      imagem: {
        icone,
      },
    };
    const respiptu: Item = {
      acao: "",
      titulo: "Quem tem que pagar pelo IPTU?",
      descricao: "Quem tem responsabilidade para com o pagamento do IPTU",
      imagem: {
        icone,
      },
    };
    const avista: Item = {
      acao: "",
      titulo: "Pagar à vista ou parcelado?",
      descricao: "O melhor pagamento é a vista ou parcelado?",
      imagem: {
        icone,
      },
    };
    const consultaiptu: Item = {
      acao: "",
      titulo: "Como consultar o IPTU?",
      descricao: "Onde posso consultar o IPTU?",
      imagem: {
        icone,
      },
    };
    const pagamentoiptu: Item = {
      acao: "",
      titulo: "Como pagar o IPTU atrasado?",
      descricao: "IPTU atrasado, como faço para pagar?",
      imagem: {
        icone,
      },
    };
    const isento: Item = {
      acao: "",
      titulo: "Quem é Isento do IPTU?",
      descricao: "Como funciona a isenção do IPTU",
      imagem: {
        icone,
      },
    };
    const valorvenal: Item = {
      acao: "",
      titulo: "O que é valor venal?",
      descricao: "Definição de Valor Venal",
      imagem: {
        icone,
      },
    };

    const calculoiptu: Item = {
      acao: "",
      titulo: "O que é levado em consideração no cálculo do IPTU?",
      descricao: "Como funciona o cálculo do IPTU?",
      imagem: {
        icone,
      },
    };

    const dividaativa: Item = {
      acao: "",
      titulo: "O que é dívida ativa?",
      descricao: "Qual a definição de dívida ativa?",
      imagem: {
        icone,
      },
    };

    const dividatributaria: Item = {
      acao: "",
      titulo: "Dívida ativa tributária X não tributária",
      descricao: "Diferença entre Dívida ativa tributária X não tributária",
      imagem: {
        icone,
      },
    };

    const consultadivida: Item = {
      acao: "",
      titulo: "Como consultar dívida ativa?",
      descricao: "Onde posso consultar minhas dívidas ativas?",
      imagem: {
        icone,
      },
    };
    const parcelamentoamig: Item = {
      acao: "",
      titulo: "O que é o parcelamento amigável?",
      descricao: "Qual a Definição de Parcelamento amigável?",
      imagem: {
        icone,
      },
    };

    const consultaparcamig: Item = {
      acao: "",
      titulo: "Onde consultar o parcelamento amigável?",
      descricao: "Onde posso realizar a consulta do Parcelamento amigável?",
      imagem: {
        icone,
      },
    };
    const indicacaofiscal: Item = {
      acao: "",
      titulo: "Onde posso encontrar a indicação fiscal do meu imóvel?",
      descricao: "onde esta localizada a indicação fiscal?",
      imagem: {
        icone,
      },
    };

    listItems.push(iptu);
    listItems.push(pagamentoiptu);
    listItems.push(respiptu);
    listItems.push(avista);
    listItems.push(consultaiptu);
    listItems.push(isento);
    listItems.push(valorvenal);
    listItems.push(calculoiptu);
    listItems.push(dividaativa);
    listItems.push(dividatributaria);
    listItems.push(consultadivida);
    listItems.push(parcelamentoamig);
    listItems.push(consultaparcamig);
    listItems.push(indicacaofiscal);

    const faqList: Lista = {
      titulo: "FAQ Tributos",
      itens: listItems,
    };

    const chatMsg: ChatMessage = {
      list: faqList,
      msgType: MessageType.List,
    };
    this.msgService.publish(chatMsg);

    await this.modalCtrl.dismiss(null, undefined, "modal-configuracoes");
  }

  async servapp() {
    this.closeModal();
    const chatMsg: ChatMessage = {
      msgType: MessageType.CollapsableApp,
    };
    this.msgService.publish(chatMsg);
  }
  async acessorapido() {
    this.closeModal();
    let modal = await this.modalController.create({
      component: ModalServicesComponent,
      cssClass: "modal-services",
    });
    return await modal.present();
  }

  sair() {
    this.globals.authSuccess = false;
    this.router.navigateByUrl("autenticacao", { replaceUrl: true });
    this.closeModal();
  }
}
