import { Status } from './../../api/resposta';
import { API } from "../../api/api";
import { Lista } from "../../api/resposta";
import { Component, Input, OnInit } from "@angular/core";
import { NavigationExtras, Router, ActivatedRoute } from "@angular/router";
import { Item } from "src/app/api/resposta";

@Component({
  selector: "app-lista",
  templateUrl: "./lista.component.html",
  styleUrls: ["./lista.component.scss"],
})
export class ListaComponent implements OnInit {
  @Input() list: Lista;
  items: Item[];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: API
  ) {}

  ngOnInit() {
    this.items = this.list?.itens;
  }

  action(item: Item) {
    switch (this.list?.titulo) {
      case "Ultimas notícias":
        this.navToNewsPage(item);
        break;
      default:
        if (item?.acao) {
          this.sendAction(item?.acao);
        } else {
          this.sendInput(item?.descricao);
        }
        break;
    }
  }

  navToNewsPage(noticia: Item) {
    const navExtras: NavigationExtras = {
      state: { news: noticia },
    };

    this.router.navigate(["/noticias"], navExtras);
  }

  sendAction(item: string) {
    this.api.acao(item);
  }

  sendInput(item: string) {
    this.api.texto(item);
  }
}