import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-itens-services',
  templateUrl: './modal-itens-services.component.html',
  styleUrls: ['./modal-itens-services.component.scss'],
})

export class ModalServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {}
  
}