import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum NavStatus {
    Leave = 'Leave',
    Enter = 'Enter',
}

@Injectable({
    providedIn: 'root'
})
export class NavigationAlertService {

    private fooSubject = new Subject<any>();

    publish(data: any) {
        this.fooSubject.next(data);
    }

    getObservable(): Subject<any> {
        return this.fooSubject;
    }
}
