import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'star-rating',
  templateUrl: './star-rating-bar.component.html',
  styleUrls: ['./star-rating-bar.component.scss'],
})
export class StarRatingBarComponent implements OnInit {

  @Input() numstar: number = 5;
  @Input() value: number = 0;
  @Output() IonClick: EventEmitter<number> = new EventEmitter<number>();

  stars: string[] = [];



  constructor() {
    {

    }
  }
  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.calculo();
  }
  calculo() {
    this.stars = []
    let tmp = this.value;
    for (let i = 0; i < this.numstar; i++, tmp--) {
      if (tmp >= 1)
        this.stars.push("star");
      else if (tmp > 0 && tmp < 1)
        this.stars.push("star-half")
      else this.stars.push("star-outline");
    }
  }

  starClicked(index) {
      this.value = index + 1;
      this.IonClick.emit(this.value);
      this.calculo();
    }
  }

