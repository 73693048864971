import { API } from './../../api/api';
import { Item, Lista } from './../../api/resposta';
import { Component, Input, OnInit } from '@angular/core';
import { ChatMessage, ChatMessageService, MessageType } from 'src/app/services/user-message-service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-cards-itens',
  templateUrl: './cards-itens.component.html',
  styleUrls: ['./cards-itens.component.scss'],
})

export class CardsItensComponent implements OnInit {

  constructor(
    private msgService: ChatMessageService,
    private modalCtrl: ModalController,
    private api: API,
  ) { }

  ngOnInit() { }


  closeModal() {
    this.modalCtrl.dismiss();
  }

  async coleta() {
    this.closeModal()
    this.api.texto("Gostaria de solicitar a coleta de entulho");
    await this.modalCtrl.dismiss(null, undefined, 'modal-services');
  }
  async iluminacao() {
    this.closeModal()
    this.api.texto("Tem uma lampada queimada");
    await this.modalCtrl.dismiss(null, undefined, 'modal-services');
  }

  async bueiro() {
    this.closeModal()
    this.api.texto("Tem um bueiro entupido");
    await this.modalCtrl.dismiss(null, undefined, 'modal-services');
  }
  async moveis() {
    this.closeModal()
    this.api.texto("Há móveis jogados");
    await this.modalCtrl.dismiss(null, undefined, 'modal-services');
  }
  async buraco() {
    this.closeModal()
    this.api.texto("Há um buraco na rua");
    await this.modalCtrl.dismiss(null, undefined, 'modal-services');
  }
  async consulta() {
    this.closeModal()
    this.api.texto("Gostaria de consultar meus protocolos");
    await this.modalCtrl.dismiss(null, undefined, 'modal-services');
  }
}

