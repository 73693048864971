import { NavigationAlertService, NavStatus } from './../../services/navigation-alert-service';
import { Card } from './../../api/resposta';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-confirmacao',
  templateUrl: './card-confirmacao.component.html',
  styleUrls: ['./card-confirmacao.component.scss'],
})
export class CardConfirmacaoComponent implements OnInit {
  @Input() card: Card;

  constructor(private NavService: NavigationAlertService) { }

  ngOnInit() { }

  redirectToUrl() {
    this.NavService.publish(NavStatus.Leave);
    let browserRef = window.open(this.card.botao.url);
  }
}
