import {
        Lista,
        TabelaHorario,
        Collapsable,
        CollapsableApp,
        CollapsableDividas,
        CardItens,
        ModalAcesso
    } from './../api/resposta';
    
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Card, Imagem } from '../api/resposta';


export enum MessageType {
  UserMessage = 'User',
  AssistantMessage = 'Assistant',
  List = 'List',
  Card = 'Card',
  Image = 'Image',
  Suggestion = 'Suggestion',
  TimeTable = 'TimeTable',
  Status = 'Status',
  Collapsable = 'Collapsable',
  CollapsableParcelamento = 'CollapsableParcelamento',
  CollapsableApp = 'CollapsableApp',
  CollapsableDividas = 'CollapsableDividas',
  acessoRapido= 'acessoRapido',
}

export class ChatMessage {
    msgType: MessageType;
    acessoRapido?: boolean;
    message?: string;
    card?: Card;
    list?: Lista;
    image?: Imagem;
    table?: TabelaHorario;
    collapsable?: Collapsable;
    collapsableParcelamento?: Collapsable;
    collapsableApp?: CollapsableApp;
    suggestion?: string[];
    collapsableDividas?: CollapsableDividas;
    
}

@Injectable({
    providedIn: 'root'
})

export class ChatMessageService {

    private fooSubject = new Subject<ChatMessage>();

    /*
    * Publica uma mensagem
    * 
    * @example
    * O método deve ser chamado pelo serviço e com um objeto do tipo ChatMessage como parâmetro: 
    * this.ChatMessageService.publish(ChatMessage);
    * 
    * @param {data} a menssagem a ser enviada
    */
    publish(data: ChatMessage) {
        this.fooSubject.next(data);
    }
    /*
    *Retorna o objeto do tipo Observable para ser assinado
    * 
    * @example
    * O método deve ser chamado pelo serviço para assinar o evento de publicação da mensagem: 
    * this.ChatMessageService.getObservable().subscribe((msg) => {});
    */
    getObservable(): Subject<ChatMessage> {
        return this.fooSubject;
    }
}
