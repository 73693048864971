import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'components',
    loadChildren: () => import('./components/components.module').then(m => m.ComponentsPageModule)
  },
  {
    path: 'intro-slide',
    loadChildren: () => import('./intro-slide/intro-slide.module').then(m => m.IntroSlidePageModule)
  },
  {
    path: 'autenticacao',
    loadChildren: () => import('./autenticacao/autenticacao.module').then( m => m.AutenticacaoPageModule)
  },
  {
    path: 'termo-de-uso',
    loadChildren: () => import('./termo-de-uso/termo-de-uso.module').then( m => m.TermoDeUsoPageModule)
  },
  {
    path: 'resetsenha',
    loadChildren: () => import('./resetsenha/resetsenha.module').then( m => m.ResetsenhaPageModule)
  },
  {
    path: 'cadastro',
    loadChildren: () => import('./cadastro/cadastro.module').then( m => m.CadastroPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppRoutingModule { }