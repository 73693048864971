import { VazioComponent } from './components/vazio/vazio.component';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { File } from "@ionic-native/file/ngx";
import { FileService } from "./services/file-service";
import { API } from "./api/api";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { SpeechRecognition } from "@ionic-native/speech-recognition/ngx";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { ConstantsService } from "./services/constants-service";
import { HttpClientModule } from "@angular/common/http";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { IonicModule, IonicRouteStrategy, NavController, NavParams } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { ComponentsPageModule } from "./components/components.module";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { NO_ERRORS_SCHEMA } from "@angular/core";
import { TextToSpeech } from "@ionic-native/text-to-speech/ngx";
// import { TextToSpeech } from "@ionic-native/text-to-speech/ngx";
// import { AutoHideDirective } from './auto-hide.directive'

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ComponentsPageModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    SpeechRecognition,
    InAppBrowser,
    ConstantsService,
    API,
    Geolocation,
    FileService,
    File,
    FileOpener,
    Clipboard,
    VazioComponent,
    SpeechRecognition,
    TextToSpeech,
    NavController,
    NavParams,
    Deeplinks,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
