import { Card } from "./../../api/resposta";

import {
  NavigationAlertService,
  NavStatus,
} from "./../../services/navigation-alert-service";
import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-card-protocolo",
  templateUrl: "./card-protocolo.component.html",
  styleUrls: ["./card-protocolo.component.scss"],
})
export class CardProtocoloComponent implements OnInit {
  @Input() card: Card;

  constructor(private NavService: NavigationAlertService) {}

  ngOnInit() {}

  redirectToUrl() {
    this.NavService.publish(NavStatus.Leave);
    let browserRef = window.open(this.card.botao.url);
  }
}
