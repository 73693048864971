import { Status } from './../../api/resposta';
import { API } from "../../api/api";
import { Lista } from "../../api/resposta";
import { NavigationAlertService, NavStatus } from './../../services/navigation-alert-service';

import {
  Component,
  Input,
  OnInit
} from "@angular/core";

import { NavigationExtras, Router, ActivatedRoute } from "@angular/router";
import { Item } from "src/app/api/resposta";

@Component({
  selector: "app-lista-solicitacao",
  templateUrl: "./lista-solicitacao.component.html",
  styleUrls: ["./lista-solicitacao.component.scss"],
})
export class ListaSolicitacaoComponent implements OnInit {
  @Input() list: Lista;
  items: Item[];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: API,
    private NavService: NavigationAlertService
  ) {}

  ngOnInit() {
    this.items = this.list?.itens;
  }

  portal156(){
    let browserRef = window.open('http://servicos.156.osasco.sp.gov.br:8080/crm/chat_atendimento/index.html');
  }
}
