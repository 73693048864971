import { NavStatus } from "./../../services/navigation-alert-service";
import { Component, Input, OnInit } from "@angular/core";
import { Card } from "src/app/api/resposta";
import { NavigationAlertService } from "src/app/services/navigation-alert-service";
import { IonHeader, Platform } from "@ionic/angular";

@Component({
  selector: "app-card-resposta",
  templateUrl: "./card-resposta.component.html",
  styleUrls: ["./card-resposta.component.scss"],

  styles: [
    `
      .lightClass {
        background-color: var(--ion-color-light);
      }
      .Class {
        background-color: var(--ion-color-primary);
      }
    `,
  ],
})
export class CardRespostaComponent implements OnInit {
  @Input() card: Card;

  constructor(
    private NavService: NavigationAlertService,
    private platform: Platform
  ) {}

  ngOnInit() {
    if (this.card.imagem) {
      const header = document.getElementById("header");
      // header.style.minHeight = "1300px";
    }
  }
  redirectToUrl() {
    // if (this.platform.is("desktop") || this.platform.is("mobileweb")) {
    //   let browserRef = window.open(this.card.botao.url);
    // } else {
    //   this.NavService.publish(NavStatus.Leave);
    //   let browserRef = window.open(this.card.botao.url, "_self", "location=no");
    // }
    this.NavService.publish(NavStatus.Leave);
    let browserRef = window.open(this.card.botao.url, "_self", "location=no");
  }

  redirectToUrl2(){
    this.NavService.publish(NavStatus.Leave);
    let browserRef = window.open(this.card.botao.url);
  }
}

