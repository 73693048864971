import { API } from "./../../api/api";
import { StarRatingBarComponent } from "./../star-rating-bar/star-rating-bar.component";
import { Component, OnInit, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-modal-classificacao-experiencia",
  templateUrl: "./modal-classificacao-experiencia.component.html",
  styleUrls: ["./modal-classificacao-experiencia.component.scss"],
})
export class ModalClassificacaoExperienciaComponent implements OnInit {
  starRating = 0;
  usrComment = "";
  usrSuggestions: Array<string> = [];
  showInput = false;

  public estrela: any[] = [
    { id: 0, url: "../../../assets/images/estrela-0-d.svg" },
    { id: 1, url: "../../../assets/images/estrela-1-d.svg" },
    { id: 2, url: "../../../assets/images/estrela-2-d.svg" },
    { id: 3, url: "../../../assets/images/estrela-3-d.svg" },
    { id: 4, url: "../../../assets/images/estrela-4-d.svg" },
  ];
  constructor(private modalCtrl: ModalController, private api: API) { }

  ngOnInit() {
    document.querySelectorAll(".ion-text-capitalize").forEach(function (e) {
      e.addEventListener("click", function () {
        if (this.color === "dark") {
          this.color = "success";
        } else {
          this.color = "dark";
        }
      });
    });
  }

  switchInput() {
    if (this.starRating <= 3) {
      this.showInput = true
    } else {
      this.showInput = true;
    }
    // this.showInput = !this.showInput;
  }


  closeModal() {
    this.modalCtrl.dismiss();
  }

  mudarEstrela(item: any) {
    console.log(item);

    item.url = "../../../assets/images/estrela-" + String(item.id) + "-h.svg";
  }

  addSuggestion(sgst: string) {
    if (this.usrSuggestions.includes(sgst)) {
      this.usrSuggestions.splice(this.usrSuggestions.indexOf(sgst), 1);
    } else {
      this.usrSuggestions.push(sgst);
    }
  }

  getStarRating(rating) {
    this.starRating = rating;
    if (rating <= 3) {
      this.showInput = true
    } else {
      this.showInput = false;
      document.getElementById("outro").setAttribute("color","dark");
    }
  }
  async sendRating() {
    let a = await this.api.rating(
      this.starRating,
      this.usrSuggestions,
      this.usrComment
    );
    if (a) {
      this.closeModal();
    }
  }
}