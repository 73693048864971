import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter
  } from '@angular/core';

@Component({
  selector: 'app-dialogo-assistente',
  templateUrl: './dialogo-assistente.component.html',
  styleUrls: ['./dialogo-assistente.component.scss'],
})
export class DialogoAssistenteComponent implements OnInit {
  @Input() msg: string;
  @Output() scrollDiag = new EventEmitter();
  
  constructor() { }

  ngOnInit() {
    this.scrollDiag.emit();
    // console.log('evento emitido');
  }

}
